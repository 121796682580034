/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-up-left-square-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2zm8.096 10.803L6 6.707v2.768a.5.5 0 01-1 0V5.5a.5.5 0 01.5-.5h3.975a.5.5 0 110 1H6.707l4.096 4.096a.5.5 0 11-.707.707"/>',
    },
});
